@import "./theme-variables.scss";

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 11px;
  top: 5px;
  left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: -3px;
  top: -2.5px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  transition: 0.4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #2196f3;
  position: absolute;
  left: -13px;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: rgb(33 150 243 / 50%);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

@media (max-width: map-get($grid-breakpoints, lg)) {
  .modal-dialog {
    width: 100% !important;
  }

  .dialog_content {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.export-container {
  color: var(--text_link_Color);
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--border_active_Color);
  border-radius: 6px;
  padding: 5px 10px;
  font-weight: 500;

  img {
    width: 14px;
  }
}
.edit_staff_sec .permissionsList {
  width: 100% !important;
}
