@import "./theme-variables.scss";

.primary_CTA {
  background-color: var(--primary_btn_Bg_Color) !important;
  color: #ffffff !important;
  border-radius: var(--border_radius) !important;
  border: none;

  &:hover {
    color: #ffffff;
  }
}

.success_CTA {
  background-color: var(--success_btn_Bg_Color);
  color: #ffffff;
  border-radius: var(--border_radius) !important;

  &:hover {
    color: #ffffff;
  }
}

.default_CTA {
  border: 1px solid var(--primary_btn_Bg_Color) !important;
  color: var(--primary_btn_Bg_Color) !important;
  border-radius: var(--border_radius) !important;

  &:hover {
    color: var(--primary_btn_Bg_Color) !important;
  }
}
