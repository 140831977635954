// Theme variables, must be included before the libraries to allow overriding defaults
//@import url("https://fonts.googleapis.com/css?family=Helvetica+Neue:300,400,700");
@import "./theme/theme-variables.scss";
@import "./theme/common.scss";

/* styles.scss */
/* You can add global styles to this file, and also import other style files */
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/themes/dark.scss";
@import "~@swimlane/ngx-datatable/themes/bootstrap.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";
/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// Custom Theming for Angular Material
@import "~@angular/material/theming";
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Theme customization
@import "theme/theme";
@import "./variables";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $B2C-App-primary: mat-palette($mat-indigo);
// $B2C-App-accent: mat-palette($mat-pink, A200, A100, A400);
// // The warn palette is optional (defaults to red).
// $B2C-App-warn: mat-palette($mat-red);
// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $B2C-App-theme: mat-light-theme(
//   (
//     color: (
//       primary: $B2C-App-primary,
//       accent: $B2C-App-accent,
//       warn: $B2C-App-warn,
//     ),
//   )
// );
// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($B2C-App-theme);
/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */
:root {
  --text_primary_Color: "#062231";
  --text_secondary_Color: "#1db2f5";
  --text_info_Color: "#6d7a83";
  --text_link_Color: "#1db2f5";
  --text_warning_Color: "#CE7E07";
  --text_success_Color: "#2aa04a";
  --text_danger_Color: "#f51b46";
  /**bgColors */
  --body_Bg_Color: "#eef3f8";
  --secondary_Body_bg: "#E9ECEF";
  --active_Bg_Color: "#1db2f5";
  --sidenav_Bg_Color: "#0e0e23";
  --addMarkup_Bg_Color: "#e9eef6";
  --datatableRow_Bg_Color: "#0e0e23";
  --tab_default_Bg_Color: "#e9ecef";
  --whySec_Bg_Color: "#e4f0fe";
  --footer_Bg_Color: "#1e3945";
  --sidenavTab_Active_Bg_Color: "#0e2133";
  --walletcard_Bg_Color: "linear-gradient(150deg, #464589 29%, #0f0f44 82%)";
  --datatable_Row_Bg_Color: "#fafafc";
  --paymentHeader_Bg_Color: "#E9EEF6";
  /*input Color*/
  --input_Label_Color: "#667085";
  --input_Border_Color: "#d8d5cd";
  /**Button Colors */
  --primary_btn_Bg_Color: "#1db2f5";
  --success_btn_Bg_Color: "#15c4a1";
  --btn_outline_Color: "#1db2f5";
  /**borders */
  --border_radius: "8px";
  --border_default_Color: "#e7e8ea";
  --border_light_Color: "#f2f4f3";
  --border_active_Color: "#1db2f5";
  /**fa-Icons Colors */
  --fa_eye_Color: "#b4bdc1";
  /**layover Colors */
  --layover_bg_color: "#FFFAEB";
  --layover_text_color: "#B54708";
}

@import "theme/cta_buttons.scss";

::ng-deep .container {
  max-width: 90% !important;
}

* {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;

  /* Custom scrollbar */
  /* Works on Chrome, Edge, and Safari */
  /* Works on Firefox */
  /* Works on Chrome, Edge, and Safari */
  scrollbar-width: thin;
  scrollbar-color: rgb(6 34 49 / 60%);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #efefef;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b5b5c7;
    border-radius: 20px;
    border: 0px solid rgb(6 34 49 / 60%);
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif !important;
  font-weight: normal;
  font-size: 16px;
  width: 100%;
  background-color: #f2f4f4 !important;
}

nav {
  &.navbar {
    @media (max-width: map-get($grid-breakpoints, md)) {
      box-shadow: 0 2px 3px 0 rgba(125, 134, 153, 0.49);
    }
  }
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop30 {
  margin-top: 30px;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginLeft20 {
  margin-left: 20px;
}

.marginTop50 {
  margin-top: 50px;
}

::-webkit-scrollbar {
  // width: 0;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--text_link_Color) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--text_link_Color) !important;
}

::ng-deep .mat-checkbox .mat-checkbox-frame {
  border-color: var(--text_link_Color) !important;
}

// .mat-form-field-appearance-fill .mat-form-field-infix {
//   bottom: 21px;
// }
.validationError_msg {
  font-size: 12px;
  padding: 4px;
  background: #fee8ec;
  color: #f51b46;
}

.form-control {
  border: 1px solid var(--input_Border_Color) !important;
  color: var(--text_primary_Color);

  &:focus {
    box-shadow: none !important;
  }
}

.cdk-overlay-container,
.cdk-overlay-pane {
  z-index: 9999 !important;
}

.form-control:focus {
  box-shadow: none;
}

input.mat-input-element {
  margin-top: 0;
}

$toolbar-breakpoint: 600px;

$link-hover-decoration: none;
$label-margin-bottom: 0;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  sm-max: 767px,
  md: 768px,
  md-max: 991px,
  lg: 992px,
  xl: 1200px,
);

$success-colors: mat-palette($mat-green, 400);
$warning-colors: mat-palette($mat-amber, 400);

.theme-wrapper {
  width: 100%;
  overflow: hidden;
}

.iti--allow-dropdown {
  width: 100%;
}

a,
.link,
.btn,
button,
input[type="checkbox"] {
  cursor: pointer;
}

.custom-select {
  display: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (min-width: map-get($grid-breakpoints, md)) {
  .mat-snack-bar-container {
    width: 100%;
    top: 40% !important;
    left: 35% !important;
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .modal-dialog {
    max-width: 100% !important;
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .modal-dialog {
    max-width: 100% !important;
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .mat-snack-bar-container {
    width: 95% !important;
    top: 40% !important;
    left: 0 !important;
  }
}

.btn-link {
  color: var(--text_link_Color) !important;
  font-size: 11px !important;
}

.btn-link:disabled,
.btn-link.disabled {
  color: var(--text_info_Color) !important;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  font-size: 10px !important;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip-inner {
  max-width: auto;
  color: #fff;
  text-align: left;
  background-color: #000;
  border-radius: 3px;
  min-width: auto;
  padding: 10px 15px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b4bdc0 !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b4bdc0 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b4bdc0 !important;
}

.validation_msg {
  font-size: 12px;
  padding: 4px;
  background: #fee8ec;
  color: #f51b46;
  line-height: 16px;
}

.mat-simple-snackbar {
  font-size: 20px;
  font-weight: 500;
}

.mat-snack-bar-container {
  background-color: #fff !important;
  color: var(--text_link_Color) !important;
  border-radius: 4px;
  padding: 35px 45px !important;
  min-height: 48px;
  max-width: 35% !important;
  min-width: 344px !important;
  position: fixed !important;
  left: 50% !important;
  transform: translateX(-52%) !important;
}

.error-msg-snack {
  .mat-simple-snackbar {
    span {
      white-space: pre-wrap !important;
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.mat-list-item-content {
  padding: 0 0 0 6px !important;
}
@media (max-width: map-get($grid-breakpoints, lg)) {
  .wid40per-xs {
    width: 40% !important;
  }

  .datatable-body-cell {
    width: 100% !important;
  }

  .modal-dialog {
    width: 100% !important;
  }

  ::-webkit-scrollbar {
    display: none !important;
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-device-width: map-get($grid-breakpoints, lg)) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .container {
    max-width: 100% !important;
  }
}

// Custom Styling for mat-snackbar

.mat-simple-snackbar-action {
  top: 4px;
  right: 11px;
  margin: 0px !important;
  position: absolute;

  & .mat-focus-indicator {
    padding: 0px;
  }

  & .mat-button .mat-button-wrapper {
    color: $white;
    background-color: red;
    padding: 3px 5px;
    font-weight: 500;
    font-size: 11px;
  }
}

// common styling for tab header component
.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    @media (max-width: map-get($grid-breakpoints, md)) {
      padding-left: 15px;
      padding-bottom: 40px;
    }

    .back-page {
      width: 30px;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        width: 23px;
      }
    }

    &-title {
      font-size: 30px;

      @media (max-width: map-get($grid-breakpoints, md)) {
        color: white;
        font-size: 16px;
      }
    }
  }
}

// Styling for display connection lost dialogue
.offline-message {
  background: #fee8ec !important;
  color: #f51b46 !important;
  border-bottom: 1px solid #f51b46 !important;
  padding: 0.875em 0.625em !important;
  z-index: 1041 !important;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;

  .offline-body {
    position: relative;
    display: flex;

    .offline-icon span {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 2px solid transparent;
      border-top: 2px solid #f51b46;
      border-bottom: 2px solid #f51b46;
      -webkit-animation: spin 1s linear infinite;
      -moz-animation: spin 1s linear infinite;
      -ms-animation: spin 1s linear infinite;
      -o-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

button:focus,
.btn.focus {
  outline: none !important;
  box-shadow: none !important;
}
